<template>
  <div class="page-wrapper theme-green-forest">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  components: { Header },
  name: 'MainLayout',
}
</script>

<style></style>
